import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { filter } from 'rxjs/operators';

import { DataSharedServicesStoreService } from '../store/data-shared-services.store.service';

@Injectable()
export class DataSharedService {
    cadastroCentroDeCusto$: BehaviorSubject<boolean> =
        new BehaviorSubject<boolean>(true);
    cadastroGrupoCentroDeCusto$: BehaviorSubject<boolean> =
        new BehaviorSubject<boolean>(true);
    cadastroFilial$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
        true
    );
    cadastroColaborador$: BehaviorSubject<boolean> =
        new BehaviorSubject<boolean>(true);
    cadastroFornecedor$: BehaviorSubject<boolean> =
        new BehaviorSubject<boolean>(true);
    cadastroTipoDeFornecedor$: BehaviorSubject<boolean> =
        new BehaviorSubject<boolean>(true);
    cadastroTipoDeContrato$: BehaviorSubject<boolean> =
        new BehaviorSubject<boolean>(true);
    cadastroProduto$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
        true
    );
    cadastroProjeto$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
        true
    );
    cadastroMapeamentoDeRateio$: BehaviorSubject<boolean> =
        new BehaviorSubject<boolean>(true);

    cadastroGrupoDeProduto$: BehaviorSubject<boolean> =
        new BehaviorSubject<boolean>(true);
    cadastroCategoriaDeProduto$: BehaviorSubject<boolean> =
        new BehaviorSubject<boolean>(true);
    cadastroTipoDeProduto$: BehaviorSubject<boolean> =
        new BehaviorSubject<boolean>(true);
    cadastroCondicaoDePagamento$: BehaviorSubject<boolean> =
        new BehaviorSubject<boolean>(true);

    comprasFormaDePagamento$: BehaviorSubject<boolean> =
        new BehaviorSubject<boolean>(true);
    comprasRegraDeAprovacao$: BehaviorSubject<boolean> =
        new BehaviorSubject<boolean>(true);
    comprasTipoDeCompra$: BehaviorSubject<boolean> =
        new BehaviorSubject<boolean>(true);
    comprasRequisicaoAbertas$: BehaviorSubject<boolean> =
        new BehaviorSubject<boolean>(true);
    comprasOrdemAbertas$: BehaviorSubject<boolean> =
        new BehaviorSubject<boolean>(true);
    comprasOrdemEncerradas$: BehaviorSubject<boolean> =
        new BehaviorSubject<boolean>(true);
    comprasCotacoes$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
        true
    );
    comprasPedidoAbertos$: BehaviorSubject<boolean> =
        new BehaviorSubject<boolean>(true);
    comprasPedidoEncerrados$: BehaviorSubject<boolean> =
        new BehaviorSubject<boolean>(true);
    comprasRecebimentoPendentes$: BehaviorSubject<boolean> =
        new BehaviorSubject<boolean>(true);
    comprasRecebimentoProcessados$: BehaviorSubject<boolean> =
        new BehaviorSubject<boolean>(true);
    comprasOrdemSugestoes$: BehaviorSubject<boolean> =
        new BehaviorSubject<boolean>(true);
    comprasOrdemProdutos$: BehaviorSubject<boolean> =
        new BehaviorSubject<boolean>(true);
    comprasPlanejamento$: BehaviorSubject<boolean> =
        new BehaviorSubject<boolean>(true);
    comprasMotivo$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
        true
    );
    comprasNivelDeAprovacao$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
        true
    );

    estoqueAlmoxarifado$: BehaviorSubject<boolean> =
        new BehaviorSubject<boolean>(true);
    estoqueSecao$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
        true
    );
    estoqueClassificacaoAuxiliar$: BehaviorSubject<boolean> =
        new BehaviorSubject<boolean>(true);
    estoqueEntrada$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
        true
    );
    estoqueSaida$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
        true
    );
    estoqueTransferencia$: BehaviorSubject<boolean> =
        new BehaviorSubject<boolean>(true);
    estoqueAjuste$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
        true
    );
    estoqueSaldos$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
        true
    );
    estoqueExpedicaoPendentes$: BehaviorSubject<boolean> =
        new BehaviorSubject<boolean>(true);
    estoqueExpedicaoProcessados$: BehaviorSubject<boolean> =
        new BehaviorSubject<boolean>(true);
    estoqueMotivo$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
        true
    );
    estoqueTipoDeRequisicao$: BehaviorSubject<boolean> =
        new BehaviorSubject<boolean>(true);
    estoqueNivelDeAprovacao$: BehaviorSubject<boolean> =
        new BehaviorSubject<boolean>(true);
    estoqueRegraDeAprovacao$: BehaviorSubject<boolean> =
        new BehaviorSubject<boolean>(true);

    crmCliente$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
    crmTipoDeCliente$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
        true
    );
    crmSituacaoDeCliente$: BehaviorSubject<boolean> =
        new BehaviorSubject<boolean>(true);
    crmCategoriaDeCliente$: BehaviorSubject<boolean> =
        new BehaviorSubject<boolean>(true);
    crmGrupoDeCliente$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
        true
    );

    financeiroFormaDePagamento$: BehaviorSubject<boolean> =
        new BehaviorSubject<boolean>(true);
    financeiroClassificacaoAuxiliar$: BehaviorSubject<boolean> =
        new BehaviorSubject<boolean>(true);
    financeiroAgencia$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
        true
    );
    financeiroCustoABC$: BehaviorSubject<boolean> =
        new BehaviorSubject<boolean>(true);
    financeiroCaixaBancos$: BehaviorSubject<boolean> =
        new BehaviorSubject<boolean>(true);
    financeiroContaCorrente$: BehaviorSubject<boolean> =
        new BehaviorSubject<boolean>(true);
    financeiroPlanoDeContas$: BehaviorSubject<boolean> =
        new BehaviorSubject<boolean>(true);
    financeiroItemLancamento$: BehaviorSubject<boolean> =
        new BehaviorSubject<boolean>(true);
    financeiroCr$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
        true
    );
    financeiroCrBx$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
        true
    );
    financeiroCrPrejuizos$: BehaviorSubject<boolean> =
        new BehaviorSubject<boolean>(true);
    financeiroCp$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
        true
    );
    financeiroCpBx$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
        true
    );
    financeiroCpLotePagamentoAtualizar$: BehaviorSubject<boolean> =
        new BehaviorSubject<boolean>(false);

    vendasFaturaAbertas$: BehaviorSubject<boolean> =
        new BehaviorSubject<boolean>(false);
    vendasFaturaEncerradas$: BehaviorSubject<boolean> =
        new BehaviorSubject<boolean>(false);
    vendasFaturaCanceladas$: BehaviorSubject<boolean> =
        new BehaviorSubject<boolean>(false);
    vendasOrdemAbertas$: BehaviorSubject<boolean> =
        new BehaviorSubject<boolean>(false);
    vendasOrdemEncerradas$: BehaviorSubject<boolean> =
        new BehaviorSubject<boolean>(false);
    vendasOrdemCanceladas$: BehaviorSubject<boolean> =
        new BehaviorSubject<boolean>(false);
    vendasPedidoAbertos$: BehaviorSubject<boolean> =
        new BehaviorSubject<boolean>(false);
    vendasPedidoEncerrados$: BehaviorSubject<boolean> =
        new BehaviorSubject<boolean>(false);
    vendasPedidoCancelados$: BehaviorSubject<boolean> =
        new BehaviorSubject<boolean>(false);
    vendasNotaServicoPendentes$: BehaviorSubject<boolean> =
        new BehaviorSubject<boolean>(false);
    vendasNotaServicoEmitidas$: BehaviorSubject<boolean> =
        new BehaviorSubject<boolean>(false);
    vendasNotaServicoCanceladas$: BehaviorSubject<boolean> =
        new BehaviorSubject<boolean>(false);
    vendasNotaProdutoPendentes$: BehaviorSubject<boolean> =
        new BehaviorSubject<boolean>(false);
    vendasNotaProdutoEmitidas$: BehaviorSubject<boolean> =
        new BehaviorSubject<boolean>(false);
    vendasNotaProdutoCanceladas$: BehaviorSubject<boolean> =
        new BehaviorSubject<boolean>(false);
    vendasNotaDestinadasRecarregar$: BehaviorSubject<boolean> =
        new BehaviorSubject<boolean>(false);

    vendasOrcamentosEncerradas$: BehaviorSubject<boolean> =
        new BehaviorSubject<boolean>(false);

    constructor(private storeService: DataSharedServicesStoreService) {
        // CADASTROS
        this.storeService
            .getCadastroCentroDeCusto()
            .pipe(filter((b) => b == true))
            .subscribe((b) => this.cadastroCentroDeCusto$.next(b));
        this.storeService
            .getCadastroGrupoCentroDeCusto()
            .pipe(filter((b) => b == true))
            .subscribe((b) => this.cadastroGrupoCentroDeCusto$.next(b));

        this.storeService
            .getFinanceiroCustoABC()
            .pipe(filter((b) => b == true))
            .subscribe((b) => this.financeiroCustoABC$.next(b));

        this.storeService
            .getCadastroFilial()
            .pipe(filter((b) => b == true))
            .subscribe((b) => this.cadastroFilial$.next(b));

        this.storeService
            .getCadastroColaborador()
            .pipe(filter((b) => b == true))
            .subscribe((b) => this.cadastroColaborador$.next(b));

        this.storeService
            .getCadastroFornecedor()
            .pipe(filter((b) => b == true))
            .subscribe((b) => this.cadastroFornecedor$.next(b));

        this.storeService
            .getCadastroTipoDeFornecedor()
            .pipe(filter((b) => b == true))
            .subscribe((b) => this.cadastroTipoDeFornecedor$.next(b));

        this.storeService
            .getCadastroTipoDeContrato()
            .pipe(filter((b) => b == true))
            .subscribe((b) => this.cadastroTipoDeContrato$.next(b));

        this.storeService
            .getCadastroProduto()
            .pipe(filter((b) => b == true))
            .subscribe((b) => this.cadastroProduto$.next(b));

        this.storeService
            .getCadastroProjeto()
            .pipe(filter((b) => b == true))
            .subscribe((b) => this.cadastroProjeto$.next(b));

        this.storeService
            .getCadastroGrupoDeProduto()
            .pipe(filter((b) => b == true))
            .subscribe((b) => this.cadastroGrupoDeProduto$.next(b));

        this.storeService
            .getCadastroCategoriaDeProduto()
            .pipe(filter((b) => b == true))
            .subscribe((b) => this.cadastroCategoriaDeProduto$.next(b));

        this.storeService
            .getCadastroTipoDeProduto()
            .pipe(filter((b) => b == true))
            .subscribe((b) => this.cadastroTipoDeProduto$.next(b));

        this.storeService
            .getCadastroCondicaoDePagamento()
            .pipe(filter((b) => b == true))
            .subscribe((b) => this.cadastroCondicaoDePagamento$.next(b));

        // COMPRAS
        this.storeService
            .getComprasFormaDePagamento()
            .pipe(filter((b) => b == true))
            .subscribe((b) => this.comprasFormaDePagamento$.next(b));

        this.storeService
            .getComprasRegraDeAprovacao()
            .pipe(filter((b) => b == true))
            .subscribe((b) => this.comprasRegraDeAprovacao$.next(b));

        this.storeService
            .getComprasTipoDeCompra()
            .pipe(filter((b) => b == true))
            .subscribe((b) => this.comprasTipoDeCompra$.next(b));

        this.storeService
            .getComprasRequisicaoProduto()
            .pipe(filter((b) => b == true))
            .subscribe((b) => this.comprasRequisicaoAbertas$.next(b));

        this.storeService
            .getComprasOrdemAbertas()
            .pipe(filter((b) => b == true))
            .subscribe((b) => this.comprasOrdemAbertas$.next(b));

        this.storeService
            .getComprasOrdemEncerradas()
            .pipe(filter((b) => b == true))
            .subscribe((b) => this.comprasOrdemEncerradas$.next(b));

        this.storeService
            .getComprasCotacoes()
            .pipe(filter((b) => b == true))
            .subscribe((b) => this.comprasCotacoes$.next(b));

        this.storeService
            .getComprasPedidoAbertos()
            .pipe(filter((b) => b == true))
            .subscribe((b) => this.comprasPedidoAbertos$.next(b));

        this.storeService
            .getComprasPedidoEncerrados()
            .pipe(filter((b) => b == true))
            .subscribe((b) => this.comprasPedidoEncerrados$.next(b));

        this.storeService
            .getComprasRecebimentoPendentes()
            .pipe(filter((b) => b == true))
            .subscribe((b) => this.comprasRecebimentoPendentes$.next(b));

        this.storeService
            .getComprasRecebimentoProcessados()
            .pipe(filter((b) => b == true))
            .subscribe((b) => this.comprasRecebimentoProcessados$.next(b));

        this.storeService
            .getComprasOrdemSugestoes()
            .pipe(filter((b) => b == true))
            .subscribe((b) => this.comprasOrdemSugestoes$.next(b));

        this.storeService
            .getComprasOrdemProdutos()
            .pipe(filter((b) => b == true))
            .subscribe((b) => this.comprasOrdemProdutos$.next(b));

        this.storeService
            .getComprasPlanejamento()
            .pipe(filter((b) => b == true))
            .subscribe((b) => this.comprasPlanejamento$.next(b));
        this.storeService
            .getComprasMotivo()
            .pipe(filter((b) => b == true))
            .subscribe((b) => this.comprasMotivo$.next(b));

        this.storeService
            .getComprasNivelDeCompra()
            .pipe(filter((b) => b == true))
            .subscribe((b) => this.comprasNivelDeAprovacao$.next(b));

        // ESTOQUE
        this.storeService
            .getEstoqueAlmoxarifado()
            .pipe(filter((b) => b == true))
            .subscribe((b) => this.estoqueAlmoxarifado$.next(b));

        this.storeService
            .getEstoqueSecao()
            .pipe(filter((b) => b == true))
            .subscribe((b) => this.estoqueSecao$.next(b));

        this.storeService
            .getEstoqueClassificacaoAuxiliar()
            .pipe(filter((b) => b == true))
            .subscribe((b) => this.estoqueClassificacaoAuxiliar$.next(b));

        this.storeService
            .getEstoqueEntrada()
            .pipe(filter((b) => b == true))
            .subscribe((b) => this.estoqueEntrada$.next(b));

        this.storeService
            .getEstoqueSaida()
            .pipe(filter((b) => b == true))
            .subscribe((b) => this.estoqueSaida$.next(b));

        this.storeService
            .getEstoqueTransferencia()
            .pipe(filter((b) => b == true))
            .subscribe((b) => this.estoqueTransferencia$.next(b));

        this.storeService
            .getEstoqueAjuste()
            .pipe(filter((b) => b == true))
            .subscribe((b) => this.estoqueAjuste$.next(b));

        this.storeService
            .getEstoqueSaldos()
            .pipe(filter((b) => b == true))
            .subscribe((b) => this.estoqueSaldos$.next(b));

        this.storeService
            .getEstoqueExpedicaoPendentes()
            .pipe(filter((b) => b == true))
            .subscribe((b) => this.estoqueExpedicaoPendentes$.next(b));

        this.storeService
            .getEstoqueExpedicaoProcessados()
            .pipe(filter((b) => b == true))
            .subscribe((b) => this.estoqueExpedicaoProcessados$.next(b));

        this.storeService
            .getEstoqueMotivo()
            .pipe(filter((b) => b == true))
            .subscribe((b) => this.estoqueMotivo$.next(b));

        this.storeService
            .getEstoqueTipoDeRequisicao()
            .pipe(filter((b) => b == true))
            .subscribe((b) => this.estoqueTipoDeRequisicao$.next(b));

        this.storeService
            .getEstoqueNivelDeAprovacao()
            .pipe(filter((b) => b == true))
            .subscribe((b) => this.estoqueNivelDeAprovacao$.next(b));

        // CRM
        this.storeService
            .getCrmCliente()
            .pipe(filter((b) => b == true))
            .subscribe((b) => this.crmCliente$.next(b));

        this.storeService
            .getCrmCategoriaDeCliente()
            .pipe(filter((b) => b == true))
            .subscribe((b) => this.crmCategoriaDeCliente$.next(b));

        this.storeService
            .getCrmTipoDeCliente()
            .pipe(filter((b) => b == true))
            .subscribe((b) => this.crmTipoDeCliente$.next(b));

        this.storeService
            .getCrmSituacaoDeCliente()
            .pipe(filter((b) => b == true))
            .subscribe((b) => this.crmSituacaoDeCliente$.next(b));

        this.storeService
            .getCrmGrupoDeCliente()
            .pipe(filter((b) => b == true))
            .subscribe((b) => this.crmGrupoDeCliente$.next(b));

        // FINANCEIRO
        this.storeService
            .getFinanceiroFormaDePagamento()
            .pipe(filter((b) => b == true))
            .subscribe((b) => this.financeiroFormaDePagamento$.next(b));

        this.storeService
            .getFinanceiroClassificacaoAuxiliar()
            .pipe(filter((b) => b == true))
            .subscribe((b) => this.financeiroClassificacaoAuxiliar$.next(b));

        this.storeService
            .getFinanceiroAgencia()
            .pipe(filter((b) => b == true))
            .subscribe((b) => this.financeiroAgencia$.next(b));

        this.storeService
            .getFinanceiroCaixaBancos()
            .pipe(filter((b) => b == true))
            .subscribe((b) => this.financeiroCaixaBancos$.next(b));

        this.storeService
            .getFinanceiroContaCorrente()
            .pipe(filter((b) => b == true))
            .subscribe((b) => this.financeiroContaCorrente$.next(b));

        this.storeService
            .getFinanceiroPlanoDeContas()
            .pipe(filter((b) => b == true))
            .subscribe((b) => this.financeiroPlanoDeContas$.next(b));

        this.storeService
            .getFinanceiroCr()
            .pipe(filter((b) => b == true))
            .subscribe((b) => this.financeiroCr$.next(b));

        this.storeService
            .getFinanceiroCrBx()
            .pipe(filter((b) => b == true))
            .subscribe((b) => this.financeiroCrBx$.next(b));

        this.storeService
            .getFinanceiroCrPrejuizos()
            .pipe(filter((b) => b == true))
            .subscribe((b) => this.financeiroCrPrejuizos$.next(b));

        this.storeService
            .getFinanceiroCp()
            .pipe(filter((b) => b == true))
            .subscribe((b) => this.financeiroCp$.next(b));

        this.storeService
            .getFinanceiroCpBx()
            .pipe(filter((b) => b == true))
            .subscribe((b) => this.financeiroCpBx$.next(b));

        this.storeService
            .getFinanceiroItemLancamento()
            .pipe(filter((b) => b == true))
            .subscribe((b) => this.financeiroItemLancamento$.next(b));

        // VENDAS
        this.storeService
            .getVendasFaturaAbertas()
            .pipe(filter((b) => b == true))
            .subscribe((b) => this.vendasFaturaAbertas$.next(b));

        this.storeService
            .getVendasFaturaProcessadas()
            .pipe(filter((b) => b == true))
            .subscribe((b) => this.vendasFaturaEncerradas$.next(b));

        this.storeService
            .getVendasFaturaCanceladas()
            .pipe(filter((b) => b == true))
            .subscribe((b) => this.vendasFaturaCanceladas$.next(b));

        this.storeService
            .getVendasOrdemAbertas()
            .pipe(filter((b) => b == true))
            .subscribe((b) => this.vendasOrdemAbertas$.next(b));

        this.storeService
            .getVendasOrdemProcessadas()
            .pipe(filter((b) => b == true))
            .subscribe((b) => this.vendasOrdemEncerradas$.next(b));

        this.storeService
            .getVendasPedidoCancelados()
            .pipe(filter((b) => b == true))
            .subscribe((b) => this.vendasPedidoCancelados$.next(b));

        this.storeService
            .getVendasPedidoAbertos()
            .pipe(filter((b) => b == true))
            .subscribe((b) => this.vendasPedidoAbertos$.next(b));

        this.storeService
            .getVendasPedidoProcessados()
            .pipe(filter((b) => b == true))
            .subscribe((b) => this.vendasPedidoEncerrados$.next(b));

        this.storeService
            .getVendasPedidoCancelados()
            .pipe(filter((b) => b == true))
            .subscribe((b) => this.vendasPedidoCancelados$.next(b));

        this.storeService
            .getVendasNotaServicoPendentes()
            .pipe(filter((b) => b == true))
            .subscribe((b) => this.vendasNotaServicoPendentes$.next(b));

        this.storeService
            .getVendasNotaServicoEmitidas()
            .pipe(filter((b) => b == true))
            .subscribe((b) => this.vendasNotaServicoEmitidas$.next(b));

        this.storeService
            .getVendasNotaServicoCanceladas()
            .pipe(filter((b) => b == true))
            .subscribe((b) => this.vendasNotaServicoCanceladas$.next(b));

        this.storeService
            .getVendasNotaProdutoPendentes()
            .pipe(filter((b) => b == true))
            .subscribe((b) => this.vendasNotaProdutoPendentes$.next(b));

        this.storeService
            .getVendasNotaProdutoEmitidas()
            .pipe(filter((b) => b == true))
            .subscribe((b) => this.vendasNotaProdutoEmitidas$.next(b));

        this.storeService
            .getVendasNotaProdutoCanceladas()
            .pipe(filter((b) => b == true))
            .subscribe((b) => this.vendasNotaProdutoCanceladas$.next(b));
    }

    // CADASTRO
    cadastroMapeamentoDeRateioRecarregar(b: boolean = true): void {
        this.storeService.dispatchCadastroMapeamentoDeRateioAction(b);
    }

    cadastroMapeamentoDeRateioItemRecarregar(b: boolean = true): void {
        this.storeService.dispatchCadastroMapeamentoDeRateioItemAction(b);
    }

    cadastroCentroDeCustoRecarregar(b: boolean = true): void {
        this.storeService.dispatchCadastroCentroDeCustoAction(b);
    }
    cadastroGrupoCentroDeCustoRecarregar(b: boolean = true): void {
        this.storeService.dispatchCadastroGrupoCentroDeCustoAction(b);
    }
    cadastroFilialRecarregar(b: boolean = true): void {
        setTimeout(
            () => this.storeService.dispatchCadastroFilialAction(b),
            1000
        );
    }
    cadastroColaboradorRecarregar(b: boolean = true): void {
        setTimeout(
            () => this.storeService.dispatchCadastroColaboradorAction(b),
            1000
        );
    }
    cadastroFornecedorRecarregar(b: boolean = true): void {
        this.storeService.dispatchCadastroFornecedorAction(b);
    }
    cadastroTipoDeFornecedorRecarregar(b: boolean = true): void {
        this.storeService.dispatchCadastroTipoDeFornecedorAction(b);
    }
    cadastroTipoDeContratoRecarregar(b: boolean = true): void {
        this.storeService.dispatchCadastroTipoDeContratoAction(b);
    }
    cadastroFornecedorContratoRecarregar(b: boolean = true): void {
        this.storeService.dispatchCadastroFornecedorContratoAction(b);
    }
    cadastroProdutoRecarregar(b: boolean = true): void {
        this.storeService.dispatchCadastroProdutoAction(b);
    }
    cadastroProjetoRecarregar(b: boolean = true): void {
        this.storeService.dispatchCadastroProjetoAction(b);
    }
    cadastroGrupoDeProdutoRecarregar(b: boolean = true): void {
        this.storeService.dispatchCadastroGrupoDeProdutoAction(b);
    }
    cadastroCategoriaDeProdutoRecarregar(b: boolean = true): void {
        this.storeService.dispatchCadastroCategoriaDeProdutoAction(b);
    }
    cadastroTipoDeProdutoRecarregar(b: boolean = true): void {
        this.storeService.dispatchCadastroTipoDeProdutoAction(b);
    }
    cadastroCondicaoDePagamentoRecarregar(b: boolean = true): void {
        this.storeService.dispatchCadastroCondicaoDePagamentoAction(b);
    }

    cadastroCondicaoDePagamentoItemRecarregar(b: boolean = true): void {
        this.storeService.dispatchCadastroCondicaoDePagamentoItemAction(b);
    }

    cadastroExportacaoDeRelatorioRecarregar(b: boolean = true): void {
        this.storeService.dispatchCadastroExportacaoDeRelatorioAction(b);
    }

    cadastroExportacaoDeRelatorioItemRecarregar(b: boolean = true): void {
        this.storeService.dispatchCadastroExportacaoDeRelatorioItemAction(b);
    }

    //COMPRAS
    comprasFormaDePagamentoRecarregar(b: boolean = true): void {
        this.storeService.dispatchComprasFormaDePagamentoAction(b);
    }
    comprasRegraDeAprovacaoRecarregar(b: boolean = true): void {
        this.storeService.dispatchComprasRegraDeAprovacaoAction(b);
    }
    comprasTipoDeCompraRecarregar(b: boolean = true): void {
        this.storeService.dispatchComprasTipoDeCompraAction(b);
    }
    comprasRequisicaoAbertasRecarregar(b: boolean = true): void {
        this.storeService.dispatchComprasRequisicaoAbertasAction(b);
    }
    comprasOrdemAbertasRecarregar(b: boolean = true): void {
        this.storeService.dispatchComprasOrdemAbertasAction(b);
        if (b) {
            this.comprasOrdemSugestoesRecarregar(b);
            this.comprasOrdemProdutosRecarregar(b);
        }
    }
    comprasOrdemEncerradasRecarregar(b: boolean = true): void {
        this.storeService.dispatchComprasOrdemEncerradasAction(b);
        if (b) {
            this.comprasOrdemSugestoesRecarregar(b);
            this.comprasOrdemProdutosRecarregar(b);
        }
    }
    comprasCotacoesRecarregar(b: boolean = true): void {
        this.storeService.dispatchComprasCotacoesAction(b);
    }
    comprasPedidoAbertosRecarregar(b: boolean = true): void {
        this.storeService.dispatchComprasPedidoAbertosAction(b);
    }
    comprasPedidoEncerradosRecarregar(b: boolean = true): void {
        this.storeService.dispatchComprasPedidoEncerradosAction(b);
    }
    comprasRecebimentoPendentesRecarregar(b: boolean = true): void {
        this.storeService.dispatchComprasRecebimentosPendentesAction(b);
    }
    comprasRecebimentoProcessadosRecarregar(b: boolean = true): void {
        this.storeService.dispatchComprasRecebimentosProcessadosAction(b);
    }
    comprasOrdemSugestoesRecarregar(b: boolean = true): void {
        this.storeService.dispatchComprasOrdemSugestoesAction(b);
    }
    comprasOrdemProdutosRecarregar(b: boolean = true): void {
        this.storeService.dispatchComprasOrdemProdutosAction(b);
    }
    comprasPlanejamentoRecarregar(b: boolean = true): void {
        this.storeService.dispatchComprasPlanejamentoAction(b);
    }
    comprasMotivoRecarregar(b: boolean = true): void {
        this.storeService.dispatchComprasMotivoAction(b);
    }
    comprasNivelDeAprovacaoRecarregar(b: boolean = true): void {
        this.storeService.dispatchComprasNivelDeAprovacaoAction(b);
    }

    //ESTOQUE
    estoqueAlmoxarifadoRecarregar(b: boolean = true): void {
        this.storeService.dispatchEstoqueAlmoxarifadoAction(b);
    }
    estoqueMapeamentoRecarregar(b: boolean = true): void {
        this.storeService.dispatchEstoqueMapeamentoAction(b);
    }
    estoqueSecaoRecarregar(b: boolean = true): void {
        this.storeService.dispatchEstoqueSecaoAction(b);
    }
    estoqueClassificacaoAuxiliarRecarregar(b: boolean = true): void {
        this.storeService.dispatchEstoqueClassificacaoAuxiliarAction(b);
    }
    estoqueEntradaRecarregar(b: boolean = true): void {
        this.storeService.dispatchEstoqueEntradaAction(b);
    }
    estoqueSaidaRecarregar(b: boolean = true): void {
        this.storeService.dispatchEstoqueSaidaAction(b);
    }
    estoqueTransferenciaRecarregar(b: boolean = true): void {
        this.storeService.dispatchEstoqueTransferenciaAction(b);
    }
    estoqueSaldosRecarregar(b: boolean = true): void {
        this.storeService.dispatchEstoqueSaldosAction(b);
    }
    estoqueExpedicaoPendentesRecarregar(b: boolean = true): void {
        this.storeService.dispatchEstoqueExpedicaoPendentesAction(b);
    }
    estoqueExpedicaoProcessadosRecarregar(b: boolean = true): void {
        this.storeService.dispatchEstoqueExpedicaoProcessadosAction(b);
    }
    estoqueMotivoRecarregar(b: boolean = true): void {
        this.storeService.dispatchEstoqueMotivoAction(b);
    }
    estoqueTipoDeRequisicaoRecarregar(b: boolean = true): void {
        this.storeService.dispatchEstoqueTipoDeRequisicaoAction(b);
    }
    estoqueNivelDeAprovacaoRecarregar(b: boolean = true): void {
        this.storeService.dispatchEstoqueNivelDeAprovacaoAction(b);
    }
    estoqueRegraDeAprovacaoRecarregar(b: boolean = true): void {
        this.storeService.dispatchEstoqueRegraDeAprovacaoAction(b);
    }

    //CRM
    crmClienteRecarregar(b: boolean = true): void {
        this.storeService.dispatchCrmClienteAction(b);
    }
    //CRM CATEGORIA DE CLIENTE
    crmCategoriaDeClienteRecarregar(b: boolean = true): void {
        this.storeService.dispatchCrmCategoriaDeClienteAction(b);
    }
    crmTipoDeClienteRecarregar(b: boolean = true): void {
        this.storeService.dispatchCrmTipoDeClienteAction(b);
    }
    crmSituacaoDeClienteRecarregar(b: boolean = true): void {
        this.storeService.dispatchCrmSituacaoDeClienteAction(b);
    }
    crmGrupoDeClienteRecarregar(b: boolean = true): void {
        this.storeService.dispatchCrmGrupoDeClienteAction(b);
    }

    //FINANCEIRO

    financeiroFormaDePagamentoRecarregar(b: boolean = true): void {
        this.storeService.dispatchFinanceiroFormaDePagamentoAction(b);
    }
    financeiroClassificacaoAuxiliarRecarregar(b: boolean = true): void {
        this.storeService.dispatchFinanceiroClassificacaoAuxiliarAction(b);
    }
    financeiroAgenciaRecarregar(b: boolean = true): void {
        this.storeService.dispatchFinanceiroAgenciaAction(b);
    }
    financeiroCustoABCRecarregar(b: boolean = true): void {
        this.storeService.dispatchFinanceiroCustoABCAction(b);
    }
    financeiroCaixaBancosRecarregar(b: boolean = true): void {
        this.storeService.dispatchFinanceiroCaixaBancosAction(b);
    }
    financeiroContaCorrenteRecarregar(b: boolean = true): void {
        this.storeService.dispatchFinanceiroContaCorrenteAction(b);
    }
    financeiroPlanoDeContasRecarregar(b: boolean = true): void {
        this.storeService.dispatchFinanceiroPlanoDeContasAction(b);
    }
    financeiroItemLancamentoRecarregar(b: boolean = true): void {
        this.storeService.dispatchFinanceiroItemLancamentoAction(b);
    }
    financeiroCarteiraRecarregar(b: boolean = true): void {
        this.storeService.dispatchFinanceiroCarteiraAction(b);
    }
    financeiroCrRecarregar(b: boolean = true): void {
        this.storeService.dispatchFinanceiroCrAction(b);
    }
    financeiroCrBxRecarregar(b: boolean = true): void {
        this.storeService.dispatchFinanceiroCrBxAction(b);
    }
    financeiroCrPrejuizosRecarregar(b: boolean = true): void {
        this.storeService.dispatchFinanceiroCrPrejuizosAction(b);
    }
    financeiroCpRecarregar(b: boolean = true): void {
        this.storeService.dispatchFinanceiroCpAction(b);
    }
    financeiroCpBxRecarregar(b: boolean = true): void {
        this.storeService.dispatchFinanceiroCpBxAction(b);
    }
    financeiroCpLotePagamentoAtualizar(b: boolean = true): void {
        this.financeiroCpLotePagamentoAtualizar$.next(b);
    }

    //VENDAS
    vendasFormaDePagamentoRecarregar(b: boolean = true): void {
        this.storeService.dispatchVendasFormaDePagamentoAction(b);
    }
    vendasFaturaAbertasRecarregar(b: boolean = true): void {
        this.storeService.dispatchVendasFaturaAbertasAction(b);
    }
    vendasFaturaProcessadasRecarregar(b: boolean = true): void {
        this.storeService.dispatchVendasFaturaProcessadasAction(b);
    }
    vendasFaturaCanceladasRecarregar(b: boolean = true): void {
        this.storeService.dispatchVendasFaturaCanceladasAction(b);
    }
    vendasOrdemAbertasRecarregar(b: boolean = true): void {
        this.storeService.dispatchVendasOrdemAbertasAction(b);
    }
    vendasOrdemProcessadasRecarregar(b: boolean = true): void {
        this.storeService.dispatchVendasOrdemProcessadasAction(b);
    }
    vendasOrdemCanceladasRecarregar(b: boolean = true): void {
        this.storeService.dispatchVendasOrdemCanceladasAction(b);
    }

    vendasPedidoAbertosRecarregar(b: boolean = true): void {
        this.storeService.dispatchVendasPedidoAbertosAction(b);
    }
    vendasPedidoProcessadosRecarregar(b: boolean = true): void {
        this.storeService.dispatchVendasPedidoProcessadosAction(b);
    }
    vendasPedidoCanceladosRecarregar(b: boolean = true): void {
        this.storeService.dispatchVendasPedidoCanceladosAction(b);
    }

    vendasNotaServicoPendentesRecarregar(b: boolean = true): void {
        this.storeService.dispatchVendasNotaServicoPendentesAction(b);
    }
    vendasNotaServicoEmitidasRecarregar(b: boolean = true): void {
        this.storeService.dispatchVendasNotaServicoEmitidasAction(b);
    }
    vendasNotaServicoCanceladasRecarregar(b: boolean = true): void {
        this.storeService.dispatchVendasNotaServicoCanceladasAction(b);
    }
    vendasNotaProdutoPendentesRecarregar(b: boolean = true): void {
        this.storeService.dispatchVendasNotaProdutoPendentesAction(b);
    }
    vendasNotaProdutoEmitidasRecarregar(b: boolean = true): void {
        this.storeService.dispatchVendasNotaProdutoEmitidasAction(b);
    }
    vendasNotaProdutoCanceladasRecarregar(b: boolean = true): void {
        this.storeService.dispatchVendasNotaProdutoCanceladasAction(b);
    }
    vendasNotaDestinadasRecarregar(b: boolean = true): void {
        this.vendasNotaDestinadasRecarregar$.next(b);
    }

    vendasOrcamentosEncerradasRecarregar(b: boolean = true): void {
        this.vendasOrcamentosEncerradas$.next(b);
    }
}
